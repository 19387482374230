import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Substrate } from '../models/substrate.model';
import { Apis } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})

export class SubstrateService {
    private substrates: Observable<Substrate[]>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public GetList(): Observable<Substrate[]> {
        this.substrates = this.http.get<Substrate[]>(this.apis.Substrate.GetList);
        return this.substrates;
    }
}
