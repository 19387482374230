import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apis } from './api.service';
import { ManufacturingSite } from '../models/manufacturing-site.model';

@Injectable({
  providedIn: 'root'
})

export class ManufacturingSiteService {
    private manufacturingSites: Observable<Array<ManufacturingSite>>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public Get(id: string): Observable<Array<ManufacturingSite>> {
        this.manufacturingSites = this.http.get<ManufacturingSite[]>(this.apis.ManufacturingSite.GetList);
        return this.manufacturingSites;
    }

    public GetList(): Observable<Array<ManufacturingSite>> {
        this.manufacturingSites = this.http.get<ManufacturingSite[]>(this.apis.ManufacturingSite.GetList);
        return this.manufacturingSites;
    }
}
