import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ManufacturingSiteService } from '../services/manufacturing-site.service';
import { ManufacturingSite } from '../models/manufacturing-site.model';

@Injectable()
export class ManufacturingSiteResolve implements Resolve<Observable<Array<ManufacturingSite>>> {
 constructor(private manufacturingSiteService: ManufacturingSiteService) { }
 resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
       ): Observable<Array<ManufacturingSite>> {
    return this.manufacturingSiteService.GetList();
  }
}
