import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SubstrateFormat } from '../models/substrate-format.model';
import { SubstrateFormatService } from '../services/substrate-format.service';

@Injectable()
export class SubstrateFormatResolve implements Resolve<Observable<Array<SubstrateFormat>>> {
  constructor(private substrateFormatService: SubstrateFormatService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<SubstrateFormat>> {
    return this.substrateFormatService.GetList();
  }
}
