import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Region } from '../models/region.model';
import { RegionService } from '../services/region.service';

@Injectable()
export class RegionResolve implements Resolve<Observable<Array<Region>>> {
  constructor(private regionService: RegionService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<Region>> {
    return this.regionService.GetList();
  }
}
