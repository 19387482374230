import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NpiSimulationService } from '../services/npi-simulation.service';

@Injectable()
export class NpiSimulationResolve implements Resolve<Observable<Array<any>>> {
 constructor(private npiSimulationService: NpiSimulationService) { }

 resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
       ): Observable<Array<any>> {
        return this.npiSimulationService.GetList();
  }
}
