import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Currency } from '../models/currency.model';
import { CurrencyService } from '../services/currency.service';

@Injectable()
export class CurrencyResolve implements Resolve<Observable<Array<Currency>>> {
  constructor(private currencyService: CurrencyService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<Currency>> {
    return this.currencyService.GetList();
  }
}
