import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apis } from '../services/api.service';
import { SubstrateFormat } from '../models/substrate-format.model';

@Injectable({
  providedIn: 'root'
})

export class SubstrateFormatService {
    private substrateFormats: Observable<SubstrateFormat[]>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public Get(id: string): Observable<SubstrateFormat[]> {
        this.substrateFormats = this.http.get<SubstrateFormat[]>(this.apis.SubstrateFormat.GetList);
        return this.substrateFormats;
    }

    public GetList(): Observable<SubstrateFormat[]> {
        this.substrateFormats = this.http.get<SubstrateFormat[]>(this.apis.SubstrateFormat.GetList);
        return this.substrateFormats;
    }
}
