import { ManufacturingSite } from './manufacturing-site.model';
import { SupplierSite } from './supplier-site.model';
import { Region } from './region.model';
import { Brand } from './brand.model';
import { Country } from './country.model';
import { Substrate } from './substrate.model';
import { Profile } from './profile.model';
import { Foil } from './foil.model';

export interface InkBreakdown {
    process: number,
    inkStation: number,
    inkDescription: string,
    inkFamilyType: string,
    consumption: number,
    coverage: number,
    overallCoverage: number,
    currency: string,
    price: number
}

export interface FoilConsumption {
    foilGrade: string,
    noOfReels: string,
    reelWidth: string,
    cutOffLength: string,
    reelsUsedForSheets: string,
    currency: string,
    price: number
}

export interface PricingSequence {
    description: string;
    machine: string;
    fedType: string;
    outputType: string;
    noOfColour: number;
}

export class Spend {
    public spendId: number;
    public substrate: number;
    public ink: number;
    public machine: number;
    public foil: number;
    public other: number;
    public fixed: number;
    public variable: number;
    public total: number;

    constructor(spendId: number,
        substrate: number,
        ink: number,
        machine: number,
        foil: number,
        other: number,
        fixed: number,
        variable: number,
        total: number
    ) {
        this.spendId = spendId;
        this.substrate = substrate;
        this.ink = ink;
        this.machine = machine;
        this.foil = foil;
        this.other = other;
        this.fixed = fixed;
        this.variable = variable;
        this.total = total;
    }
}

export class Pricing {
    static Init = ((): Pricing => {
        return new Pricing(
            null, '', '', '', 0, null, null, null, null, false, null, null, null, null, null, 0, 0, 0, []
        );
    });

    public region: Region;
    public pricingId: string;
    public sapCode: string;
    public sapDescription: string;
    public version: number;
    public brand: Brand;
    public country: Country;
    public manufacturingSite: ManufacturingSite;
    public supplierSite: SupplierSite;
    public isDuty: boolean;
    public substrate: Substrate;
    public profile: Profile;
    public inkBreakdown: InkBreakdown[];
    public foilConsumption: FoilConsumption[];
    public noOfColour: number;
    public annualVolume: number;
    public orderFrequency: number;
    public printRunSize: number;
    public pricingSequence: PricingSequence[];

    constructor(region: Region,
        pricingId: string,
        sapCode: string,
        sapDescription: string,
        version: number,
        brand: Brand,
        country: Country,
        manufacturingSite: ManufacturingSite,
        supplierSite: SupplierSite,
        isDuty: boolean,
        substrate: Substrate,
        profile: Profile,
        inkBreakdown: InkBreakdown[],
        foilConsumption: FoilConsumption[],
        noOfColour: number,
        annualVolume: number,
        orderFrequency: number,
        printRunSize: number,
        pricingSequence: PricingSequence[]
    ) {
        this.region = region;
        this.pricingId = pricingId;
        this.sapCode = sapCode;
        this.sapDescription = sapDescription;
        this.version = version;
        this.brand = brand;
        this.country = country;
        this.manufacturingSite = manufacturingSite;
        this.supplierSite = supplierSite;
        this.isDuty = isDuty;
        this.substrate = substrate;
        this.profile = profile;
        this.inkBreakdown = inkBreakdown;
        this.foilConsumption = foilConsumption;
        this.noOfColour = noOfColour;
        this.annualVolume = annualVolume;
        this.orderFrequency = orderFrequency;
        this.printRunSize = printRunSize;
        this.pricingSequence = pricingSequence;
    }
}
