import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apis } from '../services/api.service';
import { Region } from '../models/region.model';

@Injectable({
  providedIn: 'root'
})

export class RegionService {
    private regions: Observable<Region[]>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public Get(id: string): Observable<Region[]> {
        this.regions = this.http.get<Region[]>(this.apis.Region.GetList);
        return this.regions;
    }

    public GetList(): Observable<Region[]> {
        this.regions = this.http.get<Region[]>(this.apis.Region.GetList);
        return this.regions;
    }
}
