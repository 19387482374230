import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NpiSimulation, Version } from '../models/npi-simulation.model';
import { Apis } from '../services/api.service';
import { Options } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})

export class NpiSimulationService {
    private npiSimulation: Observable<Array<NpiSimulation>>;
    private version: Observable<Array<Version>>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public GetList(): Observable<Array<NpiSimulation>> {
        this.npiSimulation = this.http.get<Array<NpiSimulation>>(this.apis.NpiSimulation.GetList);
        return this.npiSimulation;
    }

    public GetVersion(npiSimulationId: string, versionId: string): Observable<Array<Version>> {
        this.version = this.http.get<Array<Version>>(this.apis.NpiSimulation.GetVersion(npiSimulationId, versionId));
        return this.version;
    }
}
