export class Currency {
    static Init = ((): Currency => {
        return new Currency(
            '', '', ''
        );
    });

    currencyId: string;
    code: string;
    description: string;

    constructor(
        currencyId: string,
        code: string,
        description: string
    ) {
        this.currencyId = currencyId;
        this.code = code;
        this.description = description;
    }
}
