import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Substrate } from '../models/substrate.model';
import { SubstrateService } from '../services/substrate.service';

@Injectable()
export class SubstrateResolve implements Resolve<Observable<Array<Substrate>>> {
  constructor(private substrateService: SubstrateService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<Substrate>> {
    return this.substrateService.GetList();
  }
}
