import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pricing, Spend } from '../models/sku-master.model';
import { Apis } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})

export class PricingService {
    private pricings: Observable<Pricing[]>;
    private spends: Observable<Spend[]>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public GetList(): Observable<Pricing[]> {
        this.pricings = this.http.get<Pricing[]>(this.apis.Pricing.GetList);
        return this.pricings;
    }

    public GetSpends(id: string): Observable<Spend[]> {
        this.spends = this.http.get<Spend[]>(this.apis.Pricing.GetSpends);
        return this.spends;
    }
}
