import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PricingService } from '../services/sku-master.service';
import { Pricing } from '../models/sku-master.model';

@Injectable()
export class SkuMasterResolve implements Resolve<Observable<Array<Pricing>>> {
  constructor(private pricingService: PricingService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Array<Pricing>> {
    return this.pricingService.GetList();
  }
}
