import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apis } from './api.service';
import { Currency } from '../models/currency.model';

@Injectable({
  providedIn: 'root'
})

export class CurrencyService {
    private currencies: Observable<Currency[]>;

    constructor(private http: HttpClient,
        private apis: Apis
    ) { }

    public Get(id: string): Observable<Currency[]> {
        this.currencies = this.http.get<Currency[]>(this.apis.Currency.GetList);
        return this.currencies;
    }

    public GetList(): Observable<Currency[]> {
        this.currencies = this.http.get<Currency[]>(this.apis.Currency.GetList);
        return this.currencies;
    }
}
