import { Currency } from './currency.model';

export class Region {
    static Init = ((): Region => {
        return new Region(
            '', '', Currency.Init()
        );
    });

    regionId: string;
    description: string;
    baseCurrency: Currency;

    constructor(
        regionId: string,
        description: string,
        baseCurrency: Currency
    ) {
        this.regionId = regionId;
        this.description = description;
        this.baseCurrency = baseCurrency;
    }
}
